import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContinue, useNews } from 'hooks';
import { AutoSizer, Grid, InfiniteLoader, WindowScroller } from 'react-virtualized';
import { Tag } from 'components/atoms/ControlTag';
import SectionTitle from 'components/atoms/SectionTitle';
import ContentCard from 'components/molecules/ContentCard';
import { getContentsGAProps } from 'utils/GAUtils';
import { clearListCache } from '../../../utils/LGLifeUtils';
import { parse } from 'query-string';
import throttle from 'lodash/throttle';
import { VERTUAL_SCROLL_THRESHOLD, WINDOW_RESIZE_THROTTLE } from 'commons/constants';
import Paths from '../../../commons/paths';

const News = () => {
  const history = useHistory();
  const keyword = parse(location.search).keyword as string;

  const { data, newsTagsData, searchOption, setSearchOption } = useNews();
  const { getTimeLine } = useContinue();

  const [newsList, setNewsList] = useState([] as MediaCardResDto[]);
  const [currentDt, setCurrentDt] = useState('');
  const [tags, setTags] = useState([] as Tag[]);
  const [sort, setSort] = useState('postDt,desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopState, setIsPopState] = useState(false);

  const controlRef = useRef<HTMLDivElement>(null);

  const [columnCount, setColumnCount] = useState(4);
  const [rowHeight, setRowHeight] = useState<number>(0);

  const totalPage = useRef<number>(0);

  const scrollSave = () => {
    const scrollPos = window.pageYOffset;
    localStorage.setItem('videoList', JSON.stringify(newsList));
    localStorage.setItem('scrollPos', scrollPos.toString());
    localStorage.setItem('currentPage', currentPage.toString());
  };

  useEffect(() => {
    if (history.action === 'POP' && newsList.length === 0 && localStorage.getItem('videoList')) {
      setNewsList(JSON.parse(localStorage.getItem('videoList') as string));
      setCurrentPage(Number(localStorage.getItem('currentPage')));
      setIsPopState(true);

      setTimeout(() => {
        window.scrollTo(0, Number(localStorage.getItem('scrollPos')));
      }, 100);
    } else if (history.action === 'PUSH') {
      clearListCache();
    }
  });

  useEffect(() => {
    const listen = history.listen(scrollSave);
    return () => {
      listen();
    };
  });

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));
    if (listData && history.action === 'POP' && listData.list === 'news') {
      setSort(listData.sort);
      setTags(listData.filter);
    } else {
      window.sessionStorage.clear();
    }

    handleScroll();

    updateThrottleHandler();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateThrottleHandler);
    window.onbeforeunload = () => {
      clearListCache();
    };

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateThrottleHandler);
    };
  }, []);

  const updateSize = () => {
    const windowSize = { width: window.innerWidth, height: window.innerHeight };

    if (windowSize) {
      // console.log("windowSize", windowSize);

      if (windowSize.width < 1024) {
        setColumnCount(1);
      } else {
        setColumnCount(4);
      }
    }
  };

  const updateThrottleHandler = useCallback(throttle(updateSize, WINDOW_RESIZE_THROTTLE), []);

  useEffect(() => {
    if (data) {
      totalPage.current = data.totalPage;

      if (isPopState && data.page === currentPage) {
        setIsPopState(false);
        return;
      }

      setCurrentDt(data.currentDt);
      if (currentPage === 1 && data.page === 1) {
        setNewsList(data.mediaCardList);
      } else if (data.totalAmount > newsList?.length) {
        setNewsList(newsList.concat(data.mediaCardList));
      }
      // else if (data.page === 1) {
      //   setCurrentPage(data.page);
      //   setNewsList(data.mediaCardList);
      // } else if (currentPage !== data.page) {
      //   setCurrentPage(data.page);
      //   setNewsList(newsList.concat(data.mediaCardList));
      // }
    }
  }, [data]);

  useEffect(() => {
    setNewsList([]);
    setCurrentPage(1);
  }, [keyword]);

  useEffect(() => {
    const listData = JSON.parse(window.sessionStorage.getItem('listData') || JSON.stringify({}));
    if (newsTagsData?.mediaBasicTagList && ['PUSH', 'REPLACE'].includes(history.action)) {
      const newTags = newsTagsData.mediaBasicTagList.map((tag: MediaBasicTagResDto) => ({
        orderNum: tag.orderNum,
        title: tag.tagNm,
        isActive: false,
      }));

      if (keyword) {
        let targetIndex = newTags.length;

        newTags.some((tag: Tag, index: number) => {
          if (tag.title === keyword) {
            targetIndex = index;
          }
        });

        newTags.splice(targetIndex, 1, {
          orderNum: targetIndex,
          title: keyword,
          isActive: true,
          isSearchKeyword: true,
          deletable: targetIndex >= newTags.length,
        });
      }

      setTags(newTags);
    } else if (newsTagsData?.mediaBasicTagList && (listData.list !== 'news' || listData.filter.length === 0)) {
      const newTags = newsTagsData.mediaBasicTagList.map((tag: MediaBasicTagResDto) => ({
        orderNum: tag.orderNum,
        title: tag.tagNm,
        isActive: false,
      }));

      if (keyword) {
        let targetIndex = newTags.length;

        newTags.some((tag: Tag, index: number) => {
          if (tag.title === keyword) {
            targetIndex = index;
          }
        });

        newTags.splice(targetIndex, 1, {
          orderNum: targetIndex,
          title: keyword,
          isActive: true,
          isSearchKeyword: true,
          deletable: targetIndex >= newTags.length,
        });
      }

      setTags(newTags);
    }
  }, [newsTagsData, keyword]);

  useEffect(() => {
    setSearchOption({
      ...searchOption,
      tagList: tags
        .filter(t => t.isActive)
        .map(t => t.title)
        .join(','),
      page: currentPage,
      sort,
      searchWord: keyword && tags.find(tag => tag.title === keyword)?.isActive ? keyword : '',
    });
    window.sessionStorage.setItem(
      'listData',
      JSON.stringify({
        list: 'news',
        sort: sort,
        filter: tags,
        currentPage: currentPage,
      })
    );
  }, [tags, sort, currentPage, keyword]);

  const handleScroll = () => {
    // lodash throttle?
    if ((window.scrollY >= 88 && window.innerWidth > 1023) || (window.scrollY >= 61 && window.innerWidth < 1024))
      controlRef.current?.classList.add('is-sticky');
    else controlRef.current?.classList.remove('is-sticky');
  };

  const _isRowLoaded = ({ index }) => {
    const rowCount = Math.floor(newsList.length / columnCount);
    return index < rowCount - 1;
  };
  const _loadMore = ({ startIndex, stopIndex }) => {
    if (totalPage.current && currentPage < totalPage.current) {
      setCurrentPage(currentPage + 1);
    }
  };

  const _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    // console.log("_cellRenderer", key)
    const news = newsList[rowIndex * columnCount + columnIndex];

    if (!news) return;

    return (
      <div key={key} style={style}>
        <ContentCard
          {...news}
          currentDt={currentDt}
          timeline={getTimeLine(news.mediaId)}
          path={`${Paths.getPath('newsDetail').replace(':mediaId', news.mediaId as string)}`}
          isSwiperSlide={false}
          key={news.mediaId}
          isNews
          GAObj={getContentsGAProps({
            contentsType: 'NEWS',
            contentsDetailType: news.contentsDetailType as string,
            title: news.title as string,
            tags: news.mediaTag as string,
            category: news.cateNm as string,
            dataContentType: '보도자료',
            dataContentBelong: '콘텐츠',
            maker: news.creatorNm as string,
            time: news.videoTotalTime,
            brightcoveId: news.videoId as string,
            mediaContentsType: news.mediaContentsType as string,
            dataContentUrl: `${Paths.getPath('newsDetail').replace(':mediaId', news.mediaId as string)}`,
            dataImgUrl: `${news?.thumbImageUri as string}`,
          })}
        />
      </div>
    );
  };

  const _calculateRowHeight = (width: number) => {
    // console.log("_calculateRowHeight");

    if (window.innerWidth < 1024) {
      setRowHeight(width * 0.6047 + 126);
    } else {
      setRowHeight((width / columnCount) * 0.5644 + 140);
    }
  };

  const onAutoSizerResize = param => {
    const { height, width } = param;
    // console.log("onAutoSizerResize (height, width)", height, width);
    _calculateRowHeight(width);
  };

  const _newList = useMemo(() => {
    return (
      <>
        {newsList && (
          <InfiniteLoader
            isRowLoaded={_isRowLoaded}
            loadMoreRows={_loadMore}
            rowCount={Math.ceil(newsList.length / columnCount)}
            threshold={VERTUAL_SCROLL_THRESHOLD}
            // ref={ref => (infiniteLoaderRef.current = ref)}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <AutoSizer disableHeight onResize={onAutoSizerResize}>
                    {({ width }) => {
                      if (width == 0) return;
                      return (
                        <Grid
                          cellRenderer={_cellRenderer}
                          columnWidth={width / columnCount}
                          columnCount={columnCount}
                          rowCount={Math.ceil(newsList.length / columnCount)}
                          rowHeight={rowHeight}
                          width={width}
                          height={height}
                          ref={registerChild}
                          overscanRowCount={VERTUAL_SCROLL_THRESHOLD}
                          onSectionRendered={({
                                                columnOverscanStartIndex,
                                                columnOverscanStopIndex,
                                                columnStartIndex,
                                                columnStopIndex,
                                                rowOverscanStartIndex,
                                                rowOverscanStopIndex,
                                                rowStartIndex,
                                                rowStopIndex,
                                              }) => {
                            onRowsRendered({
                              startIndex: rowStartIndex,
                              stopIndex: rowStopIndex,
                            });
                          }}
                          scrollTop={scrollTop}
                          autoHeight
                        />
                      );
                    }}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </>
    );
  }, [newsList, columnCount, rowHeight]);

  return (
    <>
      <div className="content_inner">
        <div className="section news_list">
          <SectionTitle title="보도자료"/>
          {/* <!-- section_cont --> */}
          <div className="section_cont section_sticky" ref={controlRef}>
            <div className="main_row card_list">
              <div className="card_row is-small has-hover">
                <div className="card_wrapper">{_newList}</div>
              </div>
            </div>
          </div>
          {/* <!-- /section_cont --> */}
        </div>
        {/* <!-- news_list --> */}
      </div>
    </>
  );
};

export default News;
